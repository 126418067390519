/* styles.css */
.button-23 {
    background-color: #ffffff;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 23px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
  }
  
  .button-23:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
  }
  
  .button-23:active {
    background-color: #f7f7f7;
    border-color: #000000;
    transform: scale(0.96);
  }
  
  .button-23:disabled {
    border-color: #dddddd;
    color: #dddddd;
    cursor: not-allowed;
    opacity: 1;
  }
  
  /* styles.css */
.custom-input {
    border: 1px solid #d1d5db; /* Tailwind's grey-400 */
    background-color: #ffffff; /* Tailwind's white */
    border-radius: 4px;
    align-self: center;
    height: 100%;
    min-height: 60px;
    margin-top: 9px;
    margin-bottom: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    display: inline-block;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
  }
  
  .custom-input:focus,
  .custom-input:not(:placeholder-shown) {
    border-color: #4b5563; /* Tailwind's grey-600 */
    outline: none; /* Remove default outline */
  }
  
.contactcustom-input {
    border: 1px solid #d1d5db; /* Tailwind's grey-400 */
    background-color: #ffffff; /* Tailwind's white */
    border-radius: 4px;
    align-self: center;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
  }
  
  .contactcustom-input:focus,
  .contactcustom-input:not(:placeholder-shown) {
    border-color: #4b5563; /* Tailwind's grey-600 */
    outline: none; /* Remove default outline */
  }
  
/* styles.css */

/* Container to center the form */
.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 20px; /* Padding for small screens */
    box-sizing: border-box;
  }
  
  /* Form container styling */
  .form-container {
    background-color: #ffffff; /* White background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for the form */
    max-width: 400px; /* Maximum width of the form */
    width: 100%;
    text-align: center;
  }
  
  /* Image styling */
  .form-image {
    width: 100%;
    max-width: 200px; /* Maximum width of the image */
    margin-bottom: 20px; /* Space between image and form */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .form-container {
      padding: 15px;
    }
  
    .form-image {
      max-width: 150px; /* Smaller image on small screens */
    }
  
    h2 {
      font-size: 1.5rem; /* Smaller font size for headings */
    }
  
    .custom-input {
      font-size: 0.875rem; /* Smaller font size for inputs */
      padding: 10px;
    }
  
    .button-23 {
      font-size: 14px; /* Smaller font size for buttons */
      padding: 10px 20px;
    }
  }
  