.slideshow-container {
    position: relative;
    width: 100%;
    height: 900px;
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transform: scale(1);
    transition: opacity 1s ease-in-out, transform 8s ease-out;
  }
  
  .slide.active {
    opacity: 1;
    transform: scale(1.15);
  }
  
  .slideshow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .slideshow-content {
    position: relative;
    z-index: 10;
    text-align: center;
    color: white;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .slideshow-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .slideshow-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .slideshow-content h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .button {
    padding: 1rem 2rem;
    color: white;
    text-decoration: none;
    background-color: #007bff;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  