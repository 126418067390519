@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Sedan+SC&display=swap');

.font-Caveat {
  font-family: 'Sedan SC', cursive;
}
