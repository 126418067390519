@import url('https://fonts.googleapis.com/css2?family=Sedan+SC&display=swap');

@media (min-width: 768px) {
    .custom-class {
      font-size: 3.3rem; /* Adjust the font size as needed */
    }
  }
  
  .hover-link:hover {
    color: purple;
    opacity:0.8;
  }