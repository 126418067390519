@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/tabler-icons.min.css);


.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  @media (min-width: 640px) {
    .container {
      max-width: 640px;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 768px;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  
  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  
  @media (min-width: 1536px) {
    .container {
      max-width: 1536px;
      padding-right: 10rem;
      padding-left: 10rem;
    }
  }
  

  @media (min-width: 640px) {
    .sm\:-bottom-px {
      bottom: -1px;
    }
  
    .sm\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media (min-width: 768px) {
    .md\:-mt-28 {
      margin-top: -7rem;
    }
  
    .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  
    .md\:rounded-br-\[100px\] {
      border-bottom-right-radius: 100px;
    }
  
    .md\:rounded-tl-\[100px\] {
      border-top-left-radius: 100px;
    }
  }
  
  @media (min-width: 1024px) {
    .lg\:col-span-2 {
      grid-column: span 2 / span 2;
    }
  
    .lg\:col-span-3 {
      grid-column: span 3 / span 3;
    }
  
    .lg\:h-screen {
      height: 100vh;
    }
  
    .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  
    .lg\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
  

  .spinner-border {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border: 0.25em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: currentColor;
    animation: spinner-border .75s linear infinite;
  }
  
  @keyframes spinner-border {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .custom-file-input {
    display: none; /* Hide the default file input */
  }
  
  .custom-file-label {
    display: inline-flex;
    align-items: center;
    padding: 15px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* background-color: #f9f9f9; */
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  
  .custom-file-label i {
    margin-right: 8px;
  }
  
  .custom-file-label.file-selected {
    background-color: #e0f7fa;
    border-color: #b2ebf2;
  }
  