

.animate-loop-scrolls {
  animation: loop-scroll-medium 750s linear infinite;
}

@keyframes loop-scroll-medium {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.boxshdw{

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: whitesmoke;
}

/* 80008099 */
/* container mx-auto px-4 py-8 */


@media (min-width: 1024px) {
  .lg\:max-w-screen-lg {
      max-width: 1624px;
  }
}

.icon-bg-96 {
  background-color: rgba(126, 34, 206, 17%);
  text-align: center;
  border-radius: 100%;
  align-self: flex-start;
  min-width: 96px;
  min-height: 96px;
  display: inline-block;
}

.hiw-step {
  color: rgba(126, 34, 206, 0.55);
  height: 100%;
  margin-top: 17px;
  font-size: 4rem;
  line-height: 3.5rem;
  font-weight: 900; /* Adjust this value for thickness */
  
}


.button-24 {
  background-color: #222222;
  border: 1px solid #222222;
  border-radius: 28px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
  color:white;
}

.button-24:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.button-24:active {
  background-color: #222222;
  border-color: #000000;
  transform: scale(0.96);
  color:white;
}

.button-24:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}

