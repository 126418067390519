.TermsCon{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 1em;
  
  }
  
  .cc{
    margin-top:-6em;
    margin-bottom:-3em;
  }
  
  
  @media (min-width: 768px)  {
    .TermsCon {
        padding: 3em;
    }
  }
  
  /* Small screens */
  @media (min-width: 581px) and (max-width: 767px) {
  .TermsCon {
      padding: 4em;
  }
  }
  
  /* Extra small screens */
  @media (min-width: 580px) {
    .TermsCon {
        padding: 5em;
    }
  }