.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(10%);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity and color as needed */
}

#solutions-submenu {
  position: absolute;
  z-index: 50; /* Ensure this is higher than the z-index of the video */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  width: max-content; /* Adjust width as needed */
}

#solutions-submenu.hidden {
  display: none;
}
.customss-bg{
  /* background-color: rgba(37, 38, 42, 0.95); */

  background-color: rgba(60,60,61,0.25);

  /* background-color: rgba(0,0,0,0.51); */
  
  border-top-width: 1px;
    border-right-width: 0px;
    /* border-bottom-width: 1px; */
    border-left-width: 0px;
}



/* Landing.css */

/* Zoom in up for the image */
.zoom-in-up {
  transition: transform 1s ease-out;
}

.zoom-in-up.in-view {
  transform: scale(1.1) translateY(-10%);
}

/* Define the keyframes for fade-in from the left */
.zoom-out-left {
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.zoom-out-left.in-view {
  transform: scale(0.9) translateX(-20px);
  opacity: 1;
}

/* Zoom out right animation */
.zoom-out-right {
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.zoom-out-right.in-view {
  transform: scale(0.9) translateX(20px);
  opacity: 1;
}