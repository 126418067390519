.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
  }
  
  .popup-content img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  