

.book-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .book-container.single-page .page-wrapper:first-child {
    margin-right: 0;
  }
  
  .book-container.single-page .page-wrapper:first-child .page-content {
    border-right: none;
  }
  


  input, select {
    appearance: none; /* Removes default styling in iOS and other browsers */
    -webkit-appearance: none; /* Specific for Safari */
    -moz-appearance: none; /* For Firefox */
    border: none; /* Reset borders */
    outline: none; /* Remove focus outline */
    background: none; /* Reset background */
  }
  
  input, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 8px;
  }

  .custom-input {
    width: 100%; /* Full width */
    padding: 1rem; /* Consistent padding */
    border: 1px solid #ccc; /* Custom border */
    border-radius: 8px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
  }
  
  select {
    width: 100%;
    padding: 1.2rem; /* Adjust padding */
    border: 1px solid #ccc; /* Border */
    border-radius: 8px; /* Rounded corners */
    font-size: 1rem;
    background-color: #fff;
    color: #333;
  }
  